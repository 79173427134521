<template>
  <div class="edit-phone user-wrapper">
    <div class="user-header">手机绑定</div>
    <div class="user-container justify-center" v-loading="isLoading">
      <Form ref="form" :model="form" :rules="rules" label-width="80px">
        <FormItem label="原手机号">
          <span>{{ oldPhone }}</span>
        </FormItem>
        <FormItem label="新手机号" prop="newPhone">
          <Input v-model="form.newPhone" placeholder="请输入手机号" :style="{ width: '450px' }"></Input>
        </FormItem>
        <FormItem label="验证码" prop="captcha">
          <Input
            v-model="form.captcha"
            placeholder="请输入验证码"
            :style="{ width: '300px', marginRight: '16px' }"
          ></Input>
          <Button :style="{ width: '136px' }" @click="onSendCaptcha">{{ buttonContent }}</Button>
        </FormItem>
        <FormItem>
          <Button type="primary" style="width: 96px" @click="onSubmit">保存</Button>
          <Button style="width: 96px" @click="onReset">取消</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
/**
 * @desc 手机绑定
 */

import { Form, FormItem, Button, Input } from 'element-ui';
import { getUserMobile, sendCaptcha, saveUserMobile } from '@/api/user';
// validate
import { checkPhone } from '@/utils/validate';

export default {
  components: {
    Form,
    FormItem,
    Button,
    Input,
  },
  data() {
    const validateNewPhone = (rule, value, callback) => {
      let msg = checkPhone(value);
      if (msg !== true) {
        callback(new Error(msg));
        return;
      }
      if (this.form.oldPhone === value) {
        callback(new Error('新手机号与旧手机号不得一致'));
        return;
      }
      callback();
    };
    const validateCaptcha = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
        return;
      }
      if (value.length !== 4) {
        callback(new Error('请输入4位数的验证码'));
        return;
      }
      callback();
    };

    return {
      isLoading: true,
      form: {
        oldPhone: '',
        newPhone: '',
        captcha: '',
      },
      rules: {
        newPhone: [{ validator: validateNewPhone, trigger: 'blur' }],
        captcha: [{ validator: validateCaptcha, trigger: 'blur' }],
      },

      buttonContent: '获取验证码',
      disabled: false,
      timeSetting: undefined, // 计时器
    };
  },
  computed: {
    oldPhone() {
      if (this.form.oldPhone) {
        return `${this.form.oldPhone.slice(0, 3)}****${this.form.oldPhone.slice(7)}`;
      } else {
        return ``;
      }
    },
  },
  methods: {
    // 发送验证码
    onSendCaptcha() {
      // 发送验证码倒计时中不可以操作
      if (this.disabled) {
        return;
      }

      this.$refs.form.validateField('newPhone', (errorMsg) => {
        if (errorMsg.length) {
          return;
        }
        sendCaptcha({
          mobile: this.form.newPhone,
          type: 1,
        }).then(() => {
          this.$message.success('验证码已发送');
          let count = 60;
          countDown(this, count);
        });
      });

      // 60秒倒计时，防止频繁点击
      function countDown(vm, count) {
        vm.disabled = true;
        vm.buttonContent = `已发送(${count}s)`;
        if (count > 0) {
          vm.timeSetting = setTimeout(() => {
            countDown(vm, count - 1);
          }, 1000);
        } else {
          vm.buttonContent = '重新获取';
          vm.disabled = false;
          clearTimeout(vm.timeSetting);
          vm.timeSetting = undefined;
        }
      }
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveUserMobile({
            mobile: this.form.newPhone,
            code: this.form.captcha,
          })
            .then(() => {
              this.$message.success('手机绑定成功');
              this.onReset();
            })
            .finally(() => {
              this.getData();
            });
        } else {
          return false;
        }
      });
    },
    onReset() {
      this.$refs.form.resetFields();
    },
    getData() {
      this.isLoading = true;
      getUserMobile({})
        .then(({ result }) => {
          this.form.oldPhone = result.mobileNo;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import '../../user.scss';

.edit-phone {
  ::v-deep .el-form-item {
    margin-bottom: 32px;

    &:last-child {
      margin-top: 72px;
    }

    .el-form-item__label {
      color: #6d7171;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
